/* eslint-disable complexity */
import {
  getSpacing,
  SpacerValueOrKey,
} from "@faire/web--source/slate/spacing/getSpacing";
import { cn } from "@faire/web--source/tools/cn";
import React, { CSSProperties } from "react";

import { isResponsiveProp, ResponsiveProp } from "../Theme/ResponsiveProp";

// eslint-disable-next-line import/no-unassigned-import
import "./native-styles.css";

export interface IFlexProps
  extends Omit<React.AllHTMLAttributes<HTMLElement>, "as" | "size"> {
  flex?: ResponsiveProp<CSSProperties["flex"]>;
  align?: ResponsiveProp<CSSProperties["alignItems"]>;
  justify?: ResponsiveProp<CSSProperties["justifyContent"]>;
  direction?: ResponsiveProp<CSSProperties["flexDirection"]>;
  gap?: ResponsiveProp<SpacerValueOrKey>;
  children?: React.ReactNode | undefined;
  // @types/styled-components uses dark magic to try to infer the correct type for `as`. As far as I can tell,
  // it doesn't work, instead opening up prop types to "any" in many cases.
  as?: any;
}

export const Flex: React.FC<
  IFlexProps & React.RefAttributes<HTMLDivElement>
> = ({
  align: alignProp,
  direction: directionProp,
  justify: justifyProp,
  gap: gapProp,
  flex: flexProp,
  style: styleProp,
  className: classNameProp,
  as: asProp,
  children,
  ref,
  ...rest
}) => {
  const vars: Record<string, string | undefined> = {};
  const classNames: string[] = [];

  // Gap
  if (isResponsiveProp(gapProp)) {
    vars["--f_flex_gap_mobile"] = getSpacing(gapProp.mobileAndAbove);
    vars["--f_flex_gap_tablet"] =
      gapProp.tabletAndAbove != null
        ? getSpacing(gapProp.tabletAndAbove)
        : vars["--f_flex_gap_mobile"];
    vars["--f_flex_gap_desktop"] =
      gapProp.desktopAndAbove != null
        ? getSpacing(gapProp.desktopAndAbove)
        : vars["--f_flex_gap_tablet"];
    vars["--f_flex_gap_xlarge"] =
      gapProp.xLargeAndAbove != null
        ? getSpacing(gapProp.xLargeAndAbove)
        : vars["--f_flex_gap_desktop"];
    vars["--f_flex_gap_xxlarge"] =
      gapProp.xxLargeDesktop != null
        ? getSpacing(gapProp.xxLargeDesktop)
        : vars["--f_flex_gap_xlarge"];
    classNames.push("f_flex_variable_gap");
  } else if (gapProp != null) {
    vars["--f_flex_gap_mobile"] = getSpacing(gapProp);
    classNames.push("f_flex_single_value_gap");
  }

  // Flex
  if (isResponsiveProp(flexProp)) {
    vars["--f_flex_flex_mobile"] = flexProp.mobileAndAbove?.toString();
    vars["--f_flex_flex_tablet"] =
      flexProp.tabletAndAbove != null
        ? flexProp.tabletAndAbove?.toString()
        : vars["--f_flex_flex_mobile"];
    vars["--f_flex_flex_desktop"] =
      flexProp.desktopAndAbove != null
        ? flexProp.desktopAndAbove?.toString()
        : vars["--f_flex_flex_tablet"];
    vars["--f_flex_flex_xlarge"] =
      flexProp.xLargeAndAbove != null
        ? flexProp.xLargeAndAbove?.toString()
        : vars["--f_flex_flex_desktop"];
    vars["--f_flex_flex_xxlarge"] =
      flexProp.xxLargeDesktop != null
        ? flexProp.xxLargeDesktop?.toString()
        : vars["--f_flex_flex_xlarge"];
    classNames.push("f_flex_variable_flex");
  } else if (flexProp != null) {
    const parts = flexProp.toString().split(" ");
    vars["--f_flex_flex_grow"] = parts[0];
    vars["--f_flex_flex_shrink"] = parts[1] ?? "1";
    vars["--f_flex_flex_basis"] = parts[2] ?? "0%";
    classNames.push("f_flex_single_value_flex_grow");
    classNames.push("f_flex_single_value_flex_shrink");
    classNames.push("f_flex_single_value_flex_basis");
  }

  // Align
  if (isResponsiveProp(alignProp)) {
    vars["--f_flex_align_mobile"] = alignProp.mobileAndAbove;
    vars["--f_flex_align_tablet"] =
      alignProp.tabletAndAbove != null
        ? alignProp.tabletAndAbove
        : vars["--f_flex_align_mobile"];
    vars["--f_flex_align_desktop"] =
      alignProp.desktopAndAbove != null
        ? alignProp.desktopAndAbove
        : vars["--f_flex_align_tablet"];
    vars["--f_flex_align_xlarge"] =
      alignProp.xLargeAndAbove != null
        ? alignProp.xLargeAndAbove
        : vars["--f_flex_align_desktop"];
    vars["--f_flex_align_xxlarge"] =
      alignProp.xxLargeDesktop != null
        ? alignProp.xxLargeDesktop
        : vars["--f_flex_align_xlarge"];
    classNames.push("f_flex_variable_align");
  } else if (alignProp != null) {
    vars["--f_flex_align_mobile"] = alignProp;
    classNames.push("f_flex_single_value_align");
  }

  // Justify
  if (isResponsiveProp(justifyProp)) {
    vars["--f_flex_justify_mobile"] = justifyProp.mobileAndAbove;
    vars["--f_flex_justify_tablet"] =
      justifyProp.tabletAndAbove != null
        ? justifyProp.tabletAndAbove
        : vars["--f_flex_justify_mobile"];
    vars["--f_flex_justify_desktop"] =
      justifyProp.desktopAndAbove != null
        ? justifyProp.desktopAndAbove
        : vars["--f_flex_justify_tablet"];
    vars["--f_flex_justify_xlarge"] =
      justifyProp.xLargeAndAbove != null
        ? justifyProp.xLargeAndAbove
        : vars["--f_flex_justify_desktop"];
    vars["--f_flex_justify_xxlarge"] =
      justifyProp.xxLargeDesktop != null
        ? justifyProp.xxLargeDesktop
        : vars["--f_flex_justify_xlarge"];
    classNames.push("f_flex_variable_justify");
  } else if (justifyProp != null) {
    vars["--f_flex_justify_mobile"] = justifyProp;
    classNames.push("f_flex_single_value_justify");
  }

  // Direction
  if (isResponsiveProp(directionProp)) {
    vars["--f_flex_direction_mobile"] = directionProp.mobileAndAbove;
    vars["--f_flex_direction_tablet"] =
      directionProp.tabletAndAbove != null
        ? directionProp.tabletAndAbove
        : vars["--f_flex_direction_mobile"];
    vars["--f_flex_direction_desktop"] =
      directionProp.desktopAndAbove != null
        ? directionProp.desktopAndAbove
        : vars["--f_flex_direction_tablet"];
    vars["--f_flex_direction_xlarge"] =
      directionProp.xLargeAndAbove != null
        ? directionProp.xLargeAndAbove
        : vars["--f_flex_direction_desktop"];
    vars["--f_flex_direction_xxlarge"] =
      directionProp.xxLargeDesktop != null
        ? directionProp.xxLargeDesktop
        : vars["--f_flex_direction_xlarge"];
    classNames.push("f_flex_variable_direction");
  } else if (directionProp != null) {
    vars["--f_flex_direction_mobile"] = directionProp;
    classNames.push("f_flex_single_value_direction");
  }

  return React.createElement(
    asProp || "div",
    {
      ...rest,
      ref: ref,
      style: { ...vars, ...styleProp },
      className: cn(classNameProp, "f_flex_base", classNames),
    },
    children
  );
};
