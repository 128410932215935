import React from "react";

import { Flex, IFlexProps } from "../Flex";

/**
 * @trackcomponent
 */
export const Row: React.FC<Omit<IFlexProps, "direction"> & React.RefAttributes<HTMLDivElement>> = (props) => {
  return (
    <Flex
      {...props}
      // HACK: Row shouldn't accept direction, but it's passed by builder.io and possibly other sources. Support it for now.
      direction={(props as unknown as IFlexProps).direction ?? "row"}
    />
  );
};
