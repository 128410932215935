import React from "react";

import { Flex, IFlexProps } from "./Flex";

/**
 * @trackcomponent
 */
export const DesktopRowMobileColumn: React.FC<IFlexProps & React.RefAttributes<HTMLDivElement>> = (
  props
) => {
  return (
    <Flex
      {...props}
      direction={{ mobileAndAbove: "column", tabletAndAbove: "row" }}
    />
  );
};
