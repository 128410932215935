import { MIN_WIDTH_BREAKPOINTS } from "@faire/web--source/common/media";

export type MinWidthBreakpoints = {
    readonly tablet: number;
    readonly desktop: number;
    readonly xlarge: number;
    readonly xxlarge?: number;
  };
  
  export const DEFAULT_MIN_WIDTH_BREAKPOINTS: MinWidthBreakpoints = {
    tablet: MIN_WIDTH_BREAKPOINTS.TABLET,
    desktop: MIN_WIDTH_BREAKPOINTS.DESKTOP,
    xlarge: MIN_WIDTH_BREAKPOINTS.XLARGE,
    xxlarge: MIN_WIDTH_BREAKPOINTS.XXLARGE,
  };
  
  export type Viewport = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isXLargeDesktop: boolean;
    isXXLargeDesktop: boolean;
    isTabletAndAbove: boolean;
    isDesktopAndAbove: boolean;
    isXLargeAndAbove: boolean;
    isTabletAndBelow: boolean;
  };
  
  export const getViewportValues = (
    width: number,
    minWidthBreakpoints: MinWidthBreakpoints
  ) => {
    const isMobile = width < minWidthBreakpoints.tablet;
    const isTablet =
      minWidthBreakpoints.tablet <= width && width < minWidthBreakpoints.desktop;
    const isDesktop =
      minWidthBreakpoints.desktop <= width && width < minWidthBreakpoints.xlarge;
    const isXLargeDesktop =
      width >= minWidthBreakpoints.xlarge &&
      width < (minWidthBreakpoints.xxlarge ?? MIN_WIDTH_BREAKPOINTS.XXLARGE);
    const isXXLargeDesktop =
      width >= (minWidthBreakpoints.xxlarge ?? MIN_WIDTH_BREAKPOINTS.XXLARGE);
    const isTabletAndAbove = width >= minWidthBreakpoints.tablet;
    const isDesktopAndAbove = width >= minWidthBreakpoints.desktop;
    const isXLargeAndAbove = width >= minWidthBreakpoints.xlarge;
    const isTabletAndBelow = !isDesktopAndAbove;
    return {
      isMobile,
      isTablet,
      isDesktop,
      isXLargeDesktop,
      isXXLargeDesktop,
      isTabletAndAbove,
      isDesktopAndAbove,
      isXLargeAndAbove,
      isTabletAndBelow,
    };
  };