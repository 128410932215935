import {
  getSpacing,
  SpacerValueOrKey,
} from "@faire/web--source/slate/spacing/getSpacing";
import { cn } from "@faire/web--source/tools/cn";
import React, { CSSProperties } from "react";

import { isResponsiveProp, ResponsiveProp } from "../Theme/ResponsiveProp";

// eslint-disable-next-line import/no-unassigned-import
import "./native-styles.css";

interface ISize {
  size: ResponsiveProp<SpacerValueOrKey>;
  width?: never;
  height?: never;
}

interface IWidth {
  width: ResponsiveProp<SpacerValueOrKey>;
  height?: never;
  size?: never;
}

interface IHeight {
  height: ResponsiveProp<SpacerValueOrKey>;
  width?: never;
  size?: never;
}

type FixedSpacerProps = ISize | IWidth | IHeight;

export type ISpacerProps = Omit<
  React.AllHTMLAttributes<HTMLDivElement>,
  "as" | "size" | "height" | "width"
> &
  FixedSpacerProps;

/**
 * @trackcomponent
 *
 * @link [Docs](https://slate.faire.team/component/spacer/web/spacer)
 */
export const Spacer: React.FC<
  ISpacerProps & {
    ref?: React.Ref<HTMLDivElement>;
  }
> = ({
  ref,
  height: heightProp,
  width: widthProp,
  size: sizeProp,
  className,
  style: styleProp,
  children,
  ...rest
}) => {
  let styles: CSSProperties = {};
  const vars: Record<string, string | undefined> = {};
  const classNames: string[] = [];

  const definedSize = sizeProp ?? widthProp ?? heightProp;
  if (definedSize != null) {
    if (isResponsiveProp(definedSize)) {
      vars["--f_spacer_size_mobile"] = getSpacing(definedSize.mobileAndAbove);
      vars["--f_spacer_size_tablet"] =
        definedSize.tabletAndAbove != null
          ? getSpacing(definedSize.tabletAndAbove)
          : vars["--f_spacer_size_mobile"];
      vars["--f_spacer_size_desktop"] =
        definedSize.desktopAndAbove != null
          ? getSpacing(definedSize.desktopAndAbove)
          : vars["--f_spacer_size_tablet"];
      vars["--f_spacer_size_xlarge"] =
        definedSize.xLargeAndAbove != null
          ? getSpacing(definedSize.xLargeAndAbove)
          : vars["--f_spacer_size_desktop"];
      vars["--f_spacer_size_xxlarge"] =
        definedSize.xxLargeDesktop != null
          ? getSpacing(definedSize.xxLargeDesktop)
          : vars["--f_spacer_size_xlarge"];
      classNames.push("f_spacer_variable_fb");
      if ((sizeProp ?? widthProp) != null) {
        classNames.push("f_spacer_variable_min_width");
      }
      if ((sizeProp ?? heightProp) != null) {
        classNames.push("f_spacer_variable_min_height");
      }
    } else {
      vars["--f_spacer_size_mobile"] = getSpacing(definedSize);
      classNames.push("f_spacer_single_value_fb");
      if ((sizeProp ?? widthProp) != null) {
        classNames.push("f_spacer_single_value_min_width");
      }
      if ((sizeProp ?? heightProp) != null) {
        classNames.push("f_spacer_single_value_min_height");
      }
    }

    styles = {
      ...(widthProp ? { height: 0 } : {}),
      ...(heightProp ? { width: 0 } : {}),
    };
  }

  return (
    <div
      ref={ref}
      style={{ ...vars, ...styles, ...styleProp }}
      className={cn(className, "f_spacer_base", classNames)}
      {...rest}
    >
      {children}
    </div>
  );
};

export type IGrowingSpacerProps = Omit<
  React.AllHTMLAttributes<HTMLDivElement>,
  "as"
> & {
  base?: ResponsiveProp<SpacerValueOrKey>;
};

/**
 * @trackcomponent
 *
 * @link [Docs](https://slate.faire.team/component/spacer/web/growing-spacer)
 */
export const GrowingSpacer: React.FC<
  IGrowingSpacerProps & {
    ref?: React.Ref<HTMLDivElement>;
  }
> = ({
  ref,
  base: sizeProp,
  className,
  style: styleProp,
  children,
  ...rest
}) => {
  const vars: Record<string, string | undefined> = {};
  const classNames: string[] = [];

  if (sizeProp != null) {
    if (isResponsiveProp(sizeProp)) {
      vars["--f_spacer_size_mobile"] = getSpacing(sizeProp.mobileAndAbove);
      vars["--f_spacer_size_tablet"] =
        sizeProp.tabletAndAbove != null
          ? getSpacing(sizeProp.tabletAndAbove)
          : vars["--f_spacer_size_mobile"];
      vars["--f_spacer_size_desktop"] =
        sizeProp.desktopAndAbove != null
          ? getSpacing(sizeProp.desktopAndAbove)
          : vars["--f_spacer_size_tablet"];
      vars["--f_spacer_size_xlarge"] =
        sizeProp.xLargeAndAbove != null
          ? getSpacing(sizeProp.xLargeAndAbove)
          : vars["--f_spacer_size_desktop"];
      vars["--f_spacer_size_xxlarge"] =
        sizeProp.xxLargeDesktop != null
          ? getSpacing(sizeProp.xxLargeDesktop)
          : vars["--f_spacer_size_xlarge"];
      classNames.push(".f_spacer_variable_fb");
    } else {
      vars["--f_spacer_size_mobile"] = getSpacing(sizeProp);
      classNames.push("f_spacer_single_value_fb");
    }
  }

  return (
    <div
      ref={ref}
      style={{ ...vars, ...styleProp }}
      className={cn(className, "f_spacer_grow", classNames)}
      {...rest}
    >
      {children}
    </div>
  );
};
