"use client";

import { useIsSSR } from "@faire/web--source/common/server-side-rendering/isSSR";
import { cn } from "@faire/web--source/tools/cn";
import React from "react";

import { useViewport } from "../hooks/useViewport";

// eslint-disable-next-line import/no-unassigned-import
import "./native-styles.css";

export interface IProps {
  mobile?: React.ReactNode;
  tablet?: React.ReactNode;
  desktop?: React.ReactNode;
  xlarge?: React.ReactNode;
  xxlarge?: React.ReactNode;
  tabletAndAbove?: React.ReactNode;
  tabletAndBelow?: React.ReactNode;
  desktopAndAbove?: React.ReactNode;
  xLargeAndAbove?: React.ReactNode;
  noJS?: boolean;
  /**
   * Avoid adding depth to the DOM by wrapping children in <span>s in noJS-mode.
   *
   * Instead, responsive classNames will be passed to children.
   */
  flat?: boolean;
}

/**
 * This component can be used to conditionally display content based on the viewport.
 *
 * If used with the "noJS" prop, if used as a React Server Component (RSC),
 * or during SSR, it will render all possible viewports,
 * hiding ones that do not apply using media queries.
 *
 * It is not valid to pass noJS={false} if rendered from a React Server Component (RSC).
 */
export const ResponsiveContentV2: React.FC<IProps> =
  // eslint-disable-next-line complexity
  (props) => {
    const {
      mobile,
      tablet,
      desktop,
      xlarge,
      xxlarge,
      tabletAndAbove,
      tabletAndBelow,
      desktopAndAbove,
      xLargeAndAbove,
      noJS,
    } = props;
    const isSSR = useIsSSR();
    const viewport = useViewport();

    if (isSSR || noJS) {
      return <StaticResponsiveContent {...props} noJS={true} />;
    }
    if (viewport.isMobile && mobile) {
      return <>{mobile}</>;
    }
    if (viewport.isTablet && tablet) {
      return <>{tablet}</>;
    }
    if (viewport.isDesktop && desktop) {
      return <>{desktop}</>;
    }
    if (viewport.isXLargeDesktop && xlarge) {
      return <>{xlarge}</>;
    }
    if (viewport.isXXLargeDesktop && xxlarge) {
      return <>{xxlarge}</>;
    }
    if (viewport.isXLargeAndAbove && xLargeAndAbove) {
      return <>{xLargeAndAbove}</>;
    }
    if (viewport.isDesktopAndAbove && desktopAndAbove) {
      return <>{desktopAndAbove}</>;
    }
    if (viewport.isTabletAndAbove && tabletAndAbove) {
      return <>{tabletAndAbove}</>;
    }
    if (viewport.isTabletAndBelow && tabletAndBelow) {
      return <>{tabletAndBelow}</>;
    }
    return null;
  };

const StaticResponsiveContent: React.FC<IProps> = (props) => {
  const {
    mobile,
    tablet,
    desktop,
    xlarge,
    xxlarge,
    tabletAndAbove,
    tabletAndBelow,
    desktopAndAbove,
    xLargeAndAbove,
    noJS,
    flat,
  } = props;

  if (noJS === false) {
    throw new Error(
      "StaticResponsiveContent is being used as a React Server Component, but noJS was set to false."
    );
  }

  const wrap = (content: React.ReactNode, className: string) => {
    if (flat) {
      return React.Children.map(content, (child) => {
        return React.cloneElement(
          child as React.ReactElement<{ className?: string }>,
          {
            className: cn(
              className,
              (child as React.ReactElement<{ className?: string }>).props
                .className
            ),
          }
        );
      });
    } else {
      return <span className={className}>{content}</span>;
    }
  };

  return (
    <>
      {mobile ? wrap(mobile, "f_r_mobileOnly") : null}
      {tablet ? wrap(tablet, "f_r_tabletOnly") : null}
      {desktop ? wrap(desktop, "f_r_desktopOnly") : null}
      {xlarge ? wrap(xlarge, "f_r_xLargeOnly") : null}
      {xxlarge ? wrap(xxlarge, "f_r_xxLargeOnly") : null}
      {tabletAndAbove ? wrap(tabletAndAbove, "f_r_tabletAndAbove") : null}
      {tabletAndBelow ? wrap(tabletAndBelow, "f_r_tabletAndBelow") : null}
      {desktopAndAbove ? wrap(desktopAndAbove, "f_r_desktopAndAbove") : null}
      {xLargeAndAbove ? wrap(xLargeAndAbove, "f_r_xLargeAndAbove") : null}
    </>
  );
};
